<template>
    <div class="professionDialog">
        <el-dialog :title="myTitle" width="40%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-form :model="professionForm" ref="professionDialogForm" :rules="professionRules">

                <el-form-item label="专业名称"  prop="name" :label-width="formLabelWidth">
                    <el-input v-model="professionForm.name" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'professionDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: null,
                dialogVisible:false, loading: false,
                professionForm:{ id: null,  licenceId: null, name: null, },
                professionRules: {
                    name: [{ required: true, message: '请输入专业名称', trigger: 'blur' }],
                },
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {

	    },
        props: {
            profession: {
                type: Object
            }
        },
        watch: {
            profession: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        this.professionForm = { id: n.id, licenceId: n.licence_id, name: n.name, };
                        if(n.id == null) this.myTitle = '新建专业'; else this.myTitle = '编辑专业';
                    }else{
                        this.professionForm = { id: null, licenceId: null, name: null,  };
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },

            submitForm(){
                console.log(this.professionForm);
                let that = this;
                this.$refs.professionDialogForm.validate((valid) => {
                    if(valid){
                        this.loading = true;
                        this.professionForm.licenceId = this.userInfo.licenceId;
                        let param = { controllerName: 'sclProfession', methodName: '/saveOrUpdate', param: this.professionForm};

                        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                            console.log('profession', code);
                            if (code == 0) {
                                this.dialogVisible = false;
                                that.$emit('ok');
                            }else if(code == -3){
                                this.$message({message: '该专业已在系统中。。', type: 'error', duration: 5000});
                            }
                            this.loading = false;
                        }).catch((error) => {
                            this.loading = false;
                        });
                    }
                });
            },
        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .professionDialog{

    }


</style>


